import React, { useState } from "react";
import navIcon from "../Assets/googleplay-08.png";
import "../Css/NavBar.css";
const navItem = [
  {
    title: "Home",
    url: "#Home",
    cName: "navItemsBtn",

    to: "",
  },
  {
    title: "About",
    url: "#About",
    cName: " navItemsBtn ",
    to: "",
  },
  {
    title: "Features",
    url: "#Features",

    cName: "nav-links navItemsBtn active",
    to: "",
  },
  {
    title: "Pricing",
    url: "#Pricing",

    cName: "nav-links navItemsBtn active",
    to: "",
  },
  {
    title: "Contact",
    url: "#Contact",

    cName: "nav-links navItemsBtn active",
    to: "",
  },
  {
    title: "Blog",
    url: "https://blog.heirloomtales.in/",

    cName: "nav-links navItemsBtn active",
    to: "https://blog.heirloomtales.in/",
  },
];

const NavBar = () => {
  const [windowName, setWindowLocation] = useState<string>("#Home");

  return (
    <nav className="sticky-top customNavbar ">
      <div className="navItemsContainer">
        <div className="navItemsBtn">
          <img className="navIcon" src={navIcon} alt="family stories" />
        </div>

        {navItem.map((items, i) => (
          <div className="navItemsBtn">
            <a
              key={i}
              href={items.url}
              className={items.cName}
              onClick={() => {
                setWindowLocation((prev) => (prev = items.url));
              }}
            >
              <span className=""> {items.title}</span>
            </a>
            <div
              className={
                windowName === items.url
                  ? ` indicator_Blue indicator `
                  : ` indicator_white indicator `
              }
            ></div>
          </div>
        ))}
        <div className="navItemsBtn downloadBtn">
          <a href="http://onelink.to/69kkck" style={{ color: "white" }}>
            Download
          </a>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
