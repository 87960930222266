import React from "react";
import "../Css/Home.css";

//const intro = require("../Assets/intro.mp4");
const About = () => {
  return (
    <div className="background_Blue">
      <div className="row pt-5" id="goldTextContainer">
        <div className="col-5 text-right"></div>
        <div className="col-7" id="goldenTextHolder">
          <text className="titleHeadersYellow">WELCOME TO HEIRLOOM TALES</text>
          <br />
          <text className="titleHeadersYellow">
            India's First-of-its-kind family app
          </text>
        </div>
      </div>
      <div className="background_Blue">
        <div className="row pt-5">
          <div className="col-12  pt-5 pb-4" id="videoHolderConatiner">
            <br />
            <br />

            <text className="video_TitleHome  ">
              How can Heirloom Tales enhance my life?
            </text>
            <br />
            <div className="text-center">
              <video
                className="pt-3 videoPlayer"
                src="../static/media/intro.mp4"
                autoPlay
                loop
                muted
                controls
              ></video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
