import React from "react";
//import intro from "../Assets/intro.mp4";
import HomePagePhone from "../Assets/Heirloom Tales website elements-02.png";

import "../Css/Home.css";
const Home: React.FC = () => {
  return (
    <div>
      <div className="bg-white">
        <div className="">
          <div className="row">
            <div className="col-5 text-right">
              <img src={HomePagePhone} alt="An app for whole family" className="phone" />
            </div>
            <div className="col-7 text-left">
              <div className="textHolder">
                <text className="titleHeadersOdd">
                  IF A FAMILY TO YOU MEANS
                </text>
                <br />
                <text className="titleHeadersEven">PASSING YOUR</text>
                <br />
                <text className="titleHeadersOdd">
                  VALUES, BELIEFS, SECRETS;
                </text>
                <br />
                <text className="titleHeadersEven">AND SEEING THEM TRAVEL</text>
                <br />
                <text className="titleHeadersOdd">INTO FUTURE,</text>
                <br />
                <text className="titleHeadersEvenSmall">
                  THEN YOU ARE JUST AT THE RIGHT PLACE ...
                </text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
