import React from "react";

function Terms() {
  return (
    <div>
      <h2 className="text-center pt-5"> Terms of Use</h2>
      <p className="text-center">
        These Terms of Use was last updated on 5th July 2021
      </p>

      <div className="text-center p-5">
        <p className="text-left">
          1. General
          <p className="border-dark border-top pt-3">
            www.heirloomtales.in an internet based portal (“Website”) and the
            mobile application Heirloom Tales (“App”) is owned and operated by
            Heirloom Tales LLP (“we/ us”), a registered LLP within the meaning
            of the Limited Liability Partnership Act, 2008 having its registered
            office at 1st Floor, Ambika 10th Road, Plot No 54, Juhu Scheme, Vile
            Parle W, Mumbai – 400049.The Website and App are hereinafter
            collectively referred to as the “Platform”.
          </p>
          <p>
            Through the Platform we, intend to offer a platform with a host of
            services, including but not limited to the following
            facilities/services (collectively “Services”) to the user of the
            Platform (“User/you/ your”):
          </p>
          <p>
            The Platform provides an easy retrieval and organisation of data
            stored in the Platform which helps users preserve, share, record,
            play, recipes, customs and stories, create groups and invite, add
            members.
          </p>
          <p>
            The Platform and the Services are available only to individuals who
            are at least 18 years old and otherwise capable of contracting, as
            required under Indian laws and not in any way prohibited by the
            applicable law to enter into these Terms of Use in the jurisdiction
            in which the individual is currently located. If you are a minor
            (i.e., you are under the age of 18 years) and continue to access the
            Platform, we will assume that your parent or legal guardian have
            consented to these Terms of Use and affirm that they accept this
            Agreement on your behalf and bear responsibility for your use and
            that you are accessing the Platform under your parent or legal
            guardian supervision. These terms and conditions for accessing the
            Platform and the Services and the content made available therein
            (“Terms/Terms of Use”) constitute a legally binding agreement
            between us and the User.
          </p>
          <p>
            Please read these Terms carefully before accessing or using the
            Platform. By (i) using the Platform or any facility or Services
            provided through the Platform in any way; or (ii) merely browsing
            the Platform; you confirm that you have the capacity to enter into a
            legally binding contract and that you have read, understood and
            irrevocably accepted and consented to the terms and conditions under
            these Terms as also the Privacy Policy available on the Platform. If
            you do not agree with these or have any reservations, please do not
            use or access our Platform.
          </p>
        </p>

        <p>
          These Terms of Use constitute an 'electronic record' within the
          meaning of the applicable law (including the Information Technology
          Act, 2000) and is generated by a computer system and does not require
          any physical or digital signatures.
        </p>

        <p>
          We are an intermediary as defined under the Information Technology
          Act, 2000 (“IT Act”) and the Information Technology Act (Intermediary
          Guidelines) Rules, 2011. These Terms are published in accordance with
          the provisions of Rule 3(1) of the Information Technology
          (Intermediaries Guidelines) Rules, 2011 that require publishing of the
          rules and regulations, Privacy Policy and Terms of Use for accessing
          and using our Platform. Our role is limited to providing a platform
          for users to upload, share and display content, created or shared by
          you and other users.
        </p>

        <p>
          We expressly disclaim any representation or warranty that the Platform
          complies with all applicable laws and regulations outside of India. If
          you are accessing or using the Platform from any location outside
          India, you do so at your own risk, and will be solely liable for
          compliance with any local laws and customs, as may be applicable. You
          undertake and confirm that in your use, you shall comply with all
          applicable laws, regulations, directives, legislations and customs
          which apply to your use of the Platform in whatever country you are
          physically located.
        </p>

        <p className="text-left">
          2. Registration
          <p className="border-top border-dark pt-3">
            When you set up a profile on the Platform, you will be asked to
            provide certain information about yourself. You agree to provide us
            accurate information, including your real name or real name of the
            entity you represent, when you create your account on the Platform.
            You agree to receive text messages (from us or our third-party
            providers) with codes to register on the Platform. We are not
            responsible for verifying the accuracy of any such information
            provided by you. However, we reserve the right to refuse/reject your
            request for creation of profile on the Platform, at our sole
            discretion, if the data or information submitted by you appears to
            be erroneous, incomplete, incorrect or fraudulent. We will treat
            information you provide as part of registration in accordance with
            our Privacy Policy. During the registration process, you must select
            a user name which is not offensive, does not offend common decency
            and does not infringe upon the rights of any third parties. User
            accounts / profiles created during registration are non-transferable
            and may be used by you only. You are solely responsible for your
            profile and any activity on it. You should take care in maintaining
            the confidentiality of your password. As soon as you become aware of
            any unauthorized use of your profile or password, you must notify us
            at controls@heirloomtales.in immediately. We will not be liable,
            under any circumstances, for any losses caused by any unauthorized
            use of your profile/user account or password.
          </p>
        </p>

        <p className="text-left">
          3. Privacy Policy
          <p className="border-top border-dark pt-3">
            Our privacy practices are set forth in our Privacy Policy as
            available on the Platform. By use of the Platform, you acknowledge
            and accept our Privacy Policy, regardless of whether you are a
            registered user or not. The Privacy Policy is part of the terms and
            is hereby incorporated by reference.
          </p>
        </p>

        <p className="text-left">
          4. Platform not to be used for anything unlawful or illegal
          <p className="border-top border-dark pt-3">
            You shall not, use the Platform to share any content which is
            obscene, pornographic, harmful for minors, discriminatory, spreading
            what may be considered as hate speech, inciting any form of violence
            or hatred against any persons, or violates any laws of India, or is
            barred from being shared by any laws of India. In particular, you
            undertake not to:
            <p>
              <ol>
                <li>
                  Impersonate any person or entity, or falsely state or
                  otherwise misrepresent your affiliation with a person or
                  entity;
                </li>
                <li>
                  Publish, post, upload, distribute or disseminate any
                  inappropriate, profane, defamatory, infringing, obscene,
                  indecent or unlawful topic, name, material or information
                  through any bookmark, tag or keyword;
                </li>
                <li>
                  Defame, abuse, harass, threaten or otherwise violate the legal
                  rights of other persons;
                </li>
                <li>
                  Upload or distribute files that contain viruses, corrupted
                  files, or any other similar software or programs that may
                  damage the operation of the Platform or of another's computer;
                </li>
                <li>
                  Upload files that contain software or other material protected
                  by applicable intellectual property laws unless you own or
                  control the rights thereto or have received all necessary
                  consents;
                </li>
                <li>
                  Engage in any activity that interferes with or disrupts access
                  to the Platform or the Services (or the servers and networks
                  which are connected to the Platform);
                </li>
                <li>
                  Attempt to gain unauthorized access to any portion or feature
                  of the Platform, any other systems or networks connected to
                  the Platform or to any of the Services offered on or through
                  the Platform, by hacking or any other illegitimate means;
                </li>
                <li>
                  Probe, scan or test the vulnerability of the Platform or any
                  network connected to the Platform, nor breach the security or
                  authentication measures on the Platform or any network
                  connected to the Platform. You may not reverse look-up, trace
                  or seek to trace any information on any other user, of or
                  visitor to, the Platform, to its source, or exploit the
                  Platform or Service or information made available or offered
                  by or through the Platform, in any way whether or not the
                  purpose is to reveal any information, including but not
                  limited to personal identification information, other than
                  your own information, as provided for by the Platform;
                </li>
                <li>
                  Disrupt or interfere with the security of, or otherwise cause
                  harm to, the Platform, systems resources, accounts, passwords,
                  servers or networks connected to or accessible through the
                  Platform or any affiliated or linked sites;
                </li>
                <li>
                  Collect or store data about other Users. (xi) Use any device
                  or software to interfere or attempt to interfere with the
                  proper working of the Platform or with any other person's use
                  of the Platform;
                </li>
                <li>
                  Use the Platform or any content thereof for any purpose that
                  is unlawful or prohibited by these Terms, or to solicit the
                  performance of any illegal activity or other activity which
                  infringes our rights or other third party;
                </li>
                <li>
                  Conduct or forward surveys, contests, pyramid schemes or chain
                  letters; (xiv) Download any file posted by another user, of a
                  service that you know, or reasonably should know, cannot be
                  legally distributed in such manner;
                </li>
                <li>
                  Falsify or delete any author attributions, legal or other
                  proper notices or proprietary designations or labels of the
                  origin or source of software or other material contained in a
                  file that is uploaded;
                </li>
                <li>
                  Violate any applicable laws or regulations for the time being
                  in force, within or outside India;
                </li>
                <li>
                  Reverse engineer, modify, copy, distribute, transmit, display,
                  perform, reproduce, publish, license, create derivative works
                  from, transfer, or sell any information or software obtained
                  from the Platform.
                </li>
              </ol>
              <p>
                Use the Platform to publish or distribute any advertising,
                promotional material, or solicitation to other users of the
                Platform to use any goods or services. For example (but without
                limitation), you may not use the Platform to conduct any
                business, to solicit the performance of any activity that is
                prohibited by law, or to solicit other users to become
                subscribers of other information services. We may at any time
                exercise editorial control over the content of the Platform.
                Violating the aforesaid terms may result in the removal of
                content, or in the limitation or termination of a user’s access
                to Platform. This shall be without prejudice to any other rights
                and remedies we have under law and/or in equity and/or under
                these Terms.
              </p>
            </p>
          </p>
        </p>

        <p className="text-left">
          5. Advertisements, web resources and third-party services
          <p className="border-top border-dark pt-3">
            The Platform may include advertisements, which may be targeted to
            content or information on the Platform, queries made through the
            Platform, or other information, in an effort to make them relevant
            to you. The types and extent of advertising on the Platform are
            subject to change. In consideration for granting you access to and
            use of the Platform, you agree that we and our third-party providers
            and partners may place such advertising on the Platform. You
            understand and agree that we do not warrant, endorse, vouch,
            guarantee, or assume responsibility for any services advertised or
            offered to users on the Platform or any hyperlinked website or
            featured in any banner or other advertising, and we will not be a
            party to, or in any way be responsible or liable for monitoring any
            transaction between you and/or other users and/or third-parties, on
            or through the Platform. If you wish to become an advertiser, you
            will be required to enter into separate and supplemental terms with
            us about providing advertising services on the Platform.
            <p>
              The Platform may also offer you the opportunity to visit links to
              other websites or to engage with third-party products or services.
              We do not control such websites and are not responsible for its
              contents or any hyperlink contained in such websites. You are
              requested to verify the accuracy of all information provided on
              the third-party web pages. Your use of a third party website is
              subject to the terms and conditions of that third party websites.
              You assume all risk arising out of your use of such websites or
              resources and we shall assume no liability for the same.
            </p>
          </p>
        </p>

        <p className="text-left">
          6. Your Content Rights and Liabilities
          <p className="border-top border-dark pt-3">
            We strongly believe in the freedom of expression and allow you to
            upload, publish or display to others via the Platform photographs,
            images, videos, texts, music, status updates, and other content on
            our Platform (“Your Content”). If you publish or upload Your
            Content, you hereby undertake to ensure that such information is
            true and correct, and is strictly of nature and context as referred
            to by the Platform. We do not have any ownership over any of the
            content shared by you and the rights in Your Content remain only
            with you. You acknowledge and agree that, as part of using the
            Platform, Your Content may be viewed by the members of your group or
            whoever you share your Content with. We take no responsibility for
            and we do not expressly or implicitly endorse, support, or guarantee
            the completeness, truthfulness, accuracy, or reliability of any of
            Your Content. You remain solely responsible for Your Content you
            post on Platform. We do not endorse and are not responsible for any
            content shared or posted on or through our Platform, and for any
            consequences resulting out of such sharing or posting. The presence
            of our logo or any trademark on Your Content does not mean that we
            have endorsed or sponsored Your Content. Further, we will not be
            liable for or responsible for the consequences of any transactions
            made or entered into by you with other users of the Platform or
            advertisers on the Platform.
            <p>
              By sharing/posting/uploading content using our Services, you grant
              us a non-exclusive, royalty-free, perpetual, irrevocable,
              transferable, sub-licensable (through multiple tiers), worldwide
              license to host, use, distribute, run, copy, publicly perform or
              display, translate, and create derivative works of Your Content,
              publish, transmit, store, display and distribute, translate,
              communicate and make available to the public, and otherwise use
              Your Content in connection with the operation or use of the
              Platform or the promotion, advertising or marketing of the
              Platform or our business partners, in any and all media or
              distribution methods. You agree that in such circumstances, you
              are not entitled to any intimation or compensation from us. This
              license also includes the right for other users of the Platform to
              access and use Your Content for their own personal,
              non-commercial, informational or scholarly use subject to our
              Terms of Use.
              <p>
                You may delete Your Content and/or account at any point. You
                understand that we may also remove or refuse to publish Your
                Content, in whole or part, at any time.
              </p>
              <p>
                We may disclose Your Content to government bodies or law
                enforcement agencies, if required to do so under any applicable
                laws or if we have good-faith belief that it is reasonably
                necessary to share the same in order to comply with any legal
                obligation or any government request; or to enforce the Terms of
                Use and the Privacy Policy; or to respond to claims that any
                content violates the rights of a third party; or to respond to
                claims that any content is defamatory; or to protect the rights
                or prevent any harm to the property, or safety of the users or
                public; or to detect, prevent or otherwise address public
                safety, fraud, security or technical issues. We may disclose
                Your Content to any party appointed or requested by applicable
                regulators to carry out investigations or audits of our
                activities.
              </p>
              <p>
                By posting Your Content on the Platform, you represent and
                warrant to us that: i) you have the ownership rights, or you
                have obtained all necessary licenses or permissions to use Your
                Content and grant us the rights to use Your Content as provided
                herein, and ii) that posting Your Content violates no
                intellectual property or personal right of others or any
                applicable law or regulation, including any laws or regulations
                requiring disclosure that you have been compensated for Your
                Content. Because you alone are responsible for Your Content, you
                may expose yourself to liability if you post or share Content
                without all necessary rights. You accept full responsibility for
                avoiding infringement of the intellectual property or personal
                rights of others or violation of laws and regulations in
                connection with Your Content. You are responsible for ensuring
                that Your Content does not violate our policies, or any
                applicable law or regulation. You agree to pay all royalties,
                fees, and any other monies owed to any person by reason of Your
                Content.
              </p>
            </p>
          </p>
        </p>

        <p className="text-left">
          7. Other Content Rights and Liabilities
          <p className="border-top border-dark pt-3">
            Our Content: The Platform, the processes and their selection and
            arrangement, including but not limited to all text, graphics, user
            interfaces, visual interfaces, sounds and music (if any), artwork
            and computer code on the Platform (excluding Your Content and the
            User Content) is owned and controlled by us. All intellectual
            property rights (including but not limited to copyrights, patents,
            etc.) pertaining to the design, structure, selection, coordination,
            expression, look and feel and arrangement of such content and/or the
            Platform, belong solely and exclusively to us. All data we collect
            about use of the Platform by you or others is our property. Our
            logo, and all other trademarks, service marks, graphics and logos
            used in connection with the Platform or the Services, are our sole
            and exclusive property.
            <p>
              User Content: All posts, texts, photos, videos, links, contents
              and other files and information and other materials uploaded,
              published or displayed by other users via the Platform and
              licensed to us will be referred to collectively as the User
              Content. We grant you a personal, limited, non-exclusive license
              to use and access Our Content and the User Content, as made
              available to you on the Platform in connection with your use of
              the Platform for your own personal, non-commercial, informational
              or scholarly use, subject to the terms and conditions of these
              Terms. Your license does not include the right to:
            </p>
            <ul>
              <li>
                copy, display, distribute, modify, publish, reproduce, store,
                transmit, post, translate or create other derivative works from,
                or sell, rent or license all or any part of Our Content and the
                User Content or Services obtained from the Platform in any
                medium to anyone, except as otherwise expressly permitted
                herein;
              </li>
              <li>
                modify, prepare derivative works of, disassemble, decompile, or
                reverse engineer any part of Our Content and the User Content
              </li>
            </ul>
            <p>
              You agree that you shall not use any deep-link, robot, spider or
              other automatic device, program, algorithm or methodology, or any
              similar or equivalent manual process, to access, acquire, copy or
              monitor any portion of the Platform or its content, or in any way
              reproduce or circumvent the navigational structure or presentation
              of the Platform or obtain or attempt to obtain any part of the
              Platform or its content through any means not specifically made
              available through the Platform.
            </p>
            <p>
              You shall not misuse the Platform by knowingly introducing
              viruses, trojans, worms, logic bombs or other material which is
              malicious or technologically harmful. You must not attempt to gain
              unauthorised access to the Platform, the server on which the
              Platform is stored or any server, computer or database connected
              to the Platform. You must not attack the Platform by any means
              whatsoever, recognized as an attack vector.
            </p>
            <p>
              We may terminate our license to you at any time with or without
              any reason. We have the right but not the obligation to refuse to
              distribute any content on the Platform or to remove content.
              Except for the rights and license granted in these Terms, we
              reserve all other rights and grant no other rights or licenses,
              implied or otherwise.
            </p>
            <p>
              All facilities used by us to store User’s data, will adhere to
              reasonable security standards no less protective than the security
              standards used by us to process and store our own information of a
              similar nature. By accessing the Platform, you acknowledge and
              agree that we are not responsible for any failure of the Platform
              to store, transfer or delete data or for the corruption or loss of
              any data, information or content contained therein. You further
              acknowledge and agree that you grant us the non-exclusive right
              and license to store and transfer your data as part of the
              Services rendered by us. We do not claim any ownership,
              intellectual property rights or other rights whatsoever outside
              the scope of what is necessary to transfer your uploaded data as
              part of the Services.
            </p>
            <p>
              The Platform contains access to third-party content, products and
              services, and it offers interactions with third parties.
              Participation or availability on the Platform does not amount to
              endorsement or verification by us. We make no warranties or
              representations about the accuracy, completeness, or timeliness of
              any content posted on the Platform by anyone. You are solely
              responsible for your own use of the Platform.
            </p>
          </p>
        </p>

        <p className="text-left">
          8. Changes to the Platform
          <p className="border-top border-dark pt-3">
            Our Platform is dynamic and may change rapidly. As such, we may
            change the Services we provide at our discretion. We may
            temporarily, or permanently, stop providing Services or any features
            to you generally. We may remove or add functionalities to our
            Platform and Services without any notice. Please be sure to keep
            visiting this page from time to time to stay updated on our latest
            changes and developments. Visit this page to see any changes that we
            may make and services that we may add or modify, from time to time.
          </p>
        </p>
        <p className="text-left">
          9. Amendments to these Terms of Use
          <p className="border-top border-dark pt-3">
            We may modify, add or delete from, these Terms of Use from time to
            time in our sole discretion. We shall not be under the obligation to
            separately or personally notify you, whether as a registered user or
            not, of any changes made to these Terms of Use. You are requested to
            regularly visit the Platform to view the most current Terms of Use.
            Your continued access to, or use of the Platform, will be deemed to
            be an acceptance of the said changes. If you do not agree with these
            Terms of Use at any time, in part or as a whole, your only remedy is
            to cancel your account or to cease use of the Platform.
          </p>
        </p>

        <p className="text-left">
          10. Disclaimer
          <p className="border-top border-dark pt-3">
            You expressly agree that use of the Platform is at your sole risk.
            The Platform, Our Content and the User Content are provided on an
            "as is" and "as available" basis and without warranties or
            representations of any kind (express, implied and statutory,
            including but not limited to the warranties of title and
            non-infringement and the implied warranties of merchantability and
            fitness for a particular purpose), all of which we disclaim to the
            fullest extent permitted by law.
            <p></p>
            We make no warranty whatsoever, including without limitation, that
            the operation of the Platform will be timely, secure, uninterrupted
            or error-free; that any defects will be corrected; that the
            Platform, including the server that makes it available, is free of
            viruses or other harmful components; or as to the accuracy,
            completeness, reliability, availability, suitability, quality,
            non-infringement, operation or result obtained from the use of Our
            Content and the User Content, or Service provided on, accessible
            from or distributed through the Platform. We do not control what our
            users post, comment, share or say on the Platform and are not
            responsible for their (or your) actions (whether online or offline).
          </p>
          <p>
            You expressly agree that you have relied on the Platform, Our
            Content, User Content and Services completely at your own discretion
            and risk and you agree that you will be solely responsible for any
            financial, personal or any other damage of any kind or loss of data
            that results from the download of Our Content, User Content and/or
            information and/or reliance on the Platform or Services offered. To
            the fullest extent permitted under applicable law, in no event shall
            we be liable for any damages (including, without limitation,
            consequential, special, incidental, indirect, or similar damages,
            personal injury (including death) and/or damage to persons or
            property, loss of profits, corruption or loss of data, business
            interruption or any other commercial damages or losses) arising out
            of or in connection with the use of or inability to use or the
            performance of the Platform or Our Content or the User Content,
            reliance on Our Content and Services and User Content, errors,
            inaccuracies, defects, untimeliness, security breaches, information
            communicated on the Platform. Further, we do not claim ownership,
            endorse or take responsibility for any third-party products,
            information, guidelines, materials or services that may be offered,
            advertised, provided or displayed on the Platform or incorporated in
            User Content, Our Content, or Services contained on, accessible from
            or distributed through the Platform. We take no
            responsibility/liability whatsoever for shortage or non-fulfillment
            of the Services. You hereby undertake that in such situation/s, you
            shall not claim any right/damages/ relief, etc. against us for
            “deficiency of service” or otherwise under the Consumer Protection
            Act, 1986 or any other applicable act/rules.
          </p>
          <p>
            We expect you to be responsible for what you post and what you see.
            If any of our users report your content to be against these Terms of
            Use, we may take enforcement actions as necessary.
          </p>
        </p>

        <p className="text-left">
          11. Indemnification
          <p className="border-top border-dark pt-3">
            You agree to indemnify, defend and hold us and our partners ,agents
            and their respective officers, directors, employees, heirs,
            successors and assigns harmless, from and against any claim,
            proceeding, loss, damage, liability, cost, demand or expense
            (including but not limited to attorney's fees) of any kind arising
            out of: (i) your access to or use of or inability to use the
            Platform or Our Content or the User Content or the Services; (ii)
            any breach by you of your obligations under this Agreement; (iii)
            your violation of the rights of any third party, including any
            infringement of intellectual property, or of any privacy or consumer
            protection right; (iv) any violation of law or contractual
            obligation and any claims, demands, notices pursuant to such
            violation; (v) your negligence or wilful misconduct.
          </p>
        </p>

        <p className="text-left">
          12. Term and Termination
          <p className="border-top border-dark pt-3">
            These Terms of Use shall remain in full force and effect starting
            upon your first use of the Platform until termination subject to the
            provisions hereof. We may terminate your access to all or any part
            of the Platform at any time, with cause (including, but not limited
            to, if we believe you have violated these Terms of Use or any law as
            may be applicable) or without cause, with or without notice,
            effective immediately. Following termination, you will not be
            permitted to use the Platform and the Services and we reserve the
            right to exercise whatever means we deem necessary to prevent
            unauthorized access of the Platform. If you wish to terminate these
            Terms of Use, you may simply discontinue using the Platform and the
            Services. All provisions of these Terms of Use, which by their
            nature should survive termination, shall survive termination,
            including, without limitation, ownership provisions, warranty
            disclaimers, indemnity and limitations of liability.
          </p>
        </p>

        <p className="text-left">
          13. General Terms
          <p className="border-top border-dark pt-3">
            These Terms of Use, the Privacy Policy and all matters arising as a
            result of or in connection with the Platform and/or the Services
            shall be governed by and construed in accordance with the laws of
            India and be subject to the exclusive jurisdiction of the Courts at
            Mumbai notwithstanding where the Platform has been accessed from.
            You expressly waive any express or implied objection to the same. If
            any provision of these Terms is held to be illegal, invalid or
            unenforceable, such provision shall be disregarded and the remaining
            provisions shall remain in full force. Headings are for reference
            purposes only and in no way define, limit, construe or describe the
            scope or extent of such section. Our failure to act or delay in
            acting with respect to any failure by you or others to comply with
            these Terms of Use does not waive or limit our right to act with
            respect to that, subsequent or similar failures. These Terms of Use
            set forth the entire understanding and agreement between you and us
            with respect to the subject matter hereof. You may not assign or
            transfer your rights or obligations under these Terms without our
            prior written consent, and any purported assignment or transfer in
            violation of this provision shall be null and void. We may freely
            assign or transfer this Agreement. These Terms inures to the benefit
            of and is binding upon the parties and their respective legal
            representatives, successors, and assigns. You consent to receive
            communications from us by email in accordance with these Terms and
            applicable law. You acknowledge and agree that all agreements,
            notices, disclosures and other communications that we provide to you
            electronically will satisfy any legal requirement that such
            communications be in writing. We reserve the right to seek all
            remedies available at law and in equity for violations of these
            Terms and/or the rules and regulations set forth on the Platform,
            including without limitation the right to block access from a
            particular internet address.
          </p>
        </p>
        <p className="text-left">
          14. Contact details
          <p className="border-top border-dark pt-3">
            If you have any questions, concerns, or suggestions regarding these
            Terms of Use or if you believe that your work has been copied in a
            way that constitutes copyright infringement, or your intellectual
            property rights have been otherwise violated, please contact us at
            Controls@heirloomtales.in We have no obligation to delete content
            that you personally may find objectionable or offensive. We
            endeavour to respond promptly to requests for content removal,
            consistent with our policies and applicable law. In accordance with
            the IT Act and rules made thereunder (as amended from time to time),
            the contact details of the Grievance Officer are provided herein
            Controls@heirloomtales.in.
          </p>
        </p>
      </div>
    </div>
  );
}

export default Terms;
