import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import "./App.css";
import About from "./Components/About";
import Faq from "./Components/Faq";
import Home from "./Components/Home";
import NavBar from "./Components/NavBar";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import Terms from "./Components/Terms";

//import AppWalkThrough from "./Components/AppWalkThrough";
const AppWalkThrough = React.lazy(() => import("./Components/AppWalkThrough"));
// import ConnectUs from ;
const ConnectUs = React.lazy(() => import("./Components/ConnectUs"));

//import NoMoreBanner from "./Components/NoMoreBanner";
const NoMoreBanner = React.lazy(() => import("./Components/NoMoreBanner"));
//import Pricing from;
const Pricing = React.lazy(() => import("./Components/Pricing"));
// import Review from "./Components/Review";
const Review = React.lazy(() => import("./Components/Review"));
// import Tips from "./Components/Tips";
const Tips = React.lazy(() => import("./Components/Tips"));
const FamilyBanner = React.lazy(() => import("./Components/FamilyBanner"));

const FeatureContainer = React.lazy(
  () => import("./Components/FeatureContainer")
);

const NoMoreItemsListWhite = [
  {
    title: "NOW, NO MORE",
    subtitle: "Scanning through the long chat to find the recipe!",
  },
  {
    title: "NOW, NO MORE",
    subtitle: "Missing out on your granny's exotic pickle recipe!",
  },
  {
    title: "NOW, NO MORE",
    subtitle: "Excuses of not being able to cook beacuse a page was torn!",
  },
];

const NoMoreItemsListBlue = [
  {
    title: "NOW, NO MORE",
    subtitle: "goofed up family memoirs!",
  },
  {
    title: "NOW, NO MORE",
    subtitle: "disheartening the kids when they demand 'once more!'",
  },
  {
    title: "NOW, NO MORE",
    subtitle: "Forgetting of folk tales or mythological stories!",
  },
];

const NoMoreItemsListWhiteLast = [
  {
    title: "NOW, NO MORE",
    subtitle: "spending hours googling on a custom on the net!",
  },
  {
    title: "NOW, NO MORE",
    subtitle: "debates on what and how to follow, in the family rituals!",
  },
  {
    title: "NOW, NO MORE",
    subtitle: "excuse of forgetting the crucial items of a custom!",
  },
];

export const Main = () => {
  return (
    <div>
      
      <NavBar />
      <section id="Home">
        <Home />
      </section>
      <section id="About">
        <About />
        <Suspense fallback={() => <div>Loading</div>}>
          <FamilyBanner />
          <FeatureContainer />
        </Suspense>
      </section>
      <section id="Features">
        <Suspense fallback={() => <div>Loading</div>}>
          <Tips />

          <AppWalkThrough />
          <NoMoreBanner items={NoMoreItemsListWhite} blue={false} />
          <NoMoreBanner items={NoMoreItemsListBlue} blue={true} />
          <NoMoreBanner
            items={NoMoreItemsListWhiteLast}
            blue={false}
            space={true}
          />
        </Suspense>
      </section>
      <section id="Pricing">
        <Suspense fallback={() => <div>Loading</div>}>
          <Review />
          <Pricing />
        </Suspense>
      </section>
      <section id="Contact">
        <Suspense fallback={() => <div>Loading</div>}>
          <ConnectUs />
        </Suspense>
      </section>
    </div>
  );
};

const App = () => {
  return (
    <>
      <Router>
        <Switch>
          <Route path="/" exact={true} component={() => <Main />}></Route>
        </Switch>
        <Switch>
          <Route
            path="/privacy"
            exact={true}
            component={() => <PrivacyPolicy />}
          ></Route>
        </Switch>
        <Switch>
          <Route
            path="/terms_conditions"
            exact={true}
            component={() => <Terms />}
          ></Route>
        </Switch>
        <Switch>
          <Route
            path="/Faq"
            exact={true}
            component={() => <Faq />}
          ></Route>
        </Switch>
      </Router>
    </>
  );
};

export default App;
