import React from "react";

const Faq = () => {
  return (
    <div>
      <h2 className="text-center pt-5">FAQ</h2>

      <div className="text-left p-5">
        <ol>
          <li>
            Getting started
            <ol>
              <li>
                About Heirloom Tales, how does it work
                <ul>
                  <li>
                    New to Heirloom Tales? We’re super excited you’re here, and
                    we can’t wait to help you connect you to your heirloom
                    legacy. Heirloom tales is app which helps you preserve and
                    pass your family treasure trove. The treasure of your
                    family’s recipe, customs and stories. View Video to
                    understand more about the application.
                  </li>
                </ul>
              </li>
            </ol>
          </li>
          <li>
            Account
            <ol>
              <li>
                How do I create an account?
                <ul>
                  <li>
                    After downloading the mobile Application on the login
                    screen, you will read signup. Click on signup, fill in the
                    required details and click on generate OTP, prompted to
                    enter a 6-digit registration code sent to you via SMS. As
                    soon as the correct code is entered you will automatically
                    sign-in and your account will be created. You can
                    alternatively signup using any of the social media login
                    like Google, Facebook and Apple.
                  </li>
                </ul>
              </li>
              <li>
                How do I update profile Picture, email, phone number?
                <ul>
                  <li>On the top right click on ≡</li>
                  <li>Click on your name prompted on the top of the menu</li>
                  <li>
                    It will open your profile page wherein you can update
                    profile Picture, email, phone number
                  </li>
                </ul>
              </li>
              <li>
                Can I change my Mobile Number?
                <ul>
                  <li>This will be considered at a later stage.</li>
                </ul>
              </li>
              <li>
                Are there any language options?
                <ul>
                  <li>
                    Sorry, currently we are only operating in English. Though we
                    intend to add more language options soon.
                  </li>
                </ul>
              </li>
              <li>
                What happens when I connect Heirloom Tales to my phone's contact
                list?
                <ul>
                  <li>
                    When you use the contact upload feature and grant Heirloom
                    Tales access to your device’s address book, Heirloom Tales
                    will access the phone numbers but we don’t store these phone
                    numbers and only process them momentarily to create
                    cryptographic hash values which enable us to more
                    efficiently connect you with these contacts. We require you
                    to give permission to upload your address book in order to
                    facilitate creating groups of your heirloom.
                  </li>
                </ul>
              </li>
              <li>
                What is the privacy Policy?
                <ul>
                  <li>
                    Our privacy policy is regularly updated on the App, to read
                    our Privacy policy please click on ≡ on top left of your
                    screen
                  </li>
                  <li>Then click on Help & Support</li>
                  <li>There you will find out Privacy Policy</li>
                </ul>
              </li>
            </ol>
          </li>
          <li>
            Groups:
            <ol>
              <li>
                Why do I create groups?
                <ul>
                  <li>
                    Heirloom Tales provides you a platform wherein you can
                    easily preserve and pass your legacy to your next
                    generation, you would want your elders or relatives to
                    preserve for you or you would want to share the legacy
                    preserved by you to your loved ones. So to easily pass the
                    preserved legacy you need to create groups.
                  </li>
                </ul>
              </li>
              <li>
                How do I create groups?
                <ul>
                  <li>On the home screen you will find a button “My Groups”</li>
                  <li>Click on Create New Group</li>
                  <li>Write Group Name</li>
                  <li>Click Add members from address book</li>
                  <li>Select all members to be added</li>
                  <li>Click on sent Invite</li>
                  <li>
                    And the group is created, though the member added has to
                    accept your invitation to join the group.
                  </li>
                </ul>
              </li>
              <li>
                How do I share on group?
                <ul>
                  <li>If you are the creator of the content</li>
                  <li>
                    While creating the recipe, custom or story you can click on
                    “share the recipe”
                  </li>
                  <li>
                    Select the group or members you would like to share with
                  </li>
                </ul>
              </li>
              <li>
                Can someone else delete from the group?
                <ul>
                  <li>
                    NO, only the member who has created the content can delete
                    it.
                  </li>
                </ul>
              </li>
              <li>
                How do I remove a member from the group?
                <ul>
                  <li>On the Home-screen, click My groups</li>
                  <li>Select the group</li>
                  <li>It will show you list of members</li>
                  <li>Below the member name, click “Remove Member”</li>
                </ul>
              </li>
              <li>
                How many groups can I create?
                <ul>
                  <li>
                    As many as you desire to preserve and pass your legacy.
                    There is no restriction.
                  </li>
                </ul>
              </li>
              <li>
                How many members can I invite in the group?
                <ul>
                  <li>As many as you desire to share your legacy with.</li>
                </ul>
              </li>
              <li>
                Can I merge two groups?
                <ul>
                  <li>No, you cannot merge groups</li>
                </ul>
              </li>
              <li>
                Can I remove myself from a group?
                <ul>
                  <li>Yes, you can</li>
                  <li>To go “My Groups”</li>
                  <li>Click on group name to open</li>
                  <li>Below your name click on “Exit Group”</li>
                </ul>
              </li>
            </ol>
          </li>
          <li>
            Recipe:
            <ul>
              <li>
                How do I save a recipe?
                <ul>
                  <li>There are two ways.</li>
                  <li>One: On the home-screen click on “Our Recipes”</li>
                  <li>
                    Click on “Add New”
                    <ol>
                      <li>Write the name of Recipe (Mandatory Field)</li>
                      <li>
                        Select Type of Cuisine from the dropdown menu (Mandatory
                        Field)
                      </li>
                      <li>Select the Cooking time</li>
                      <li>Type how many people does the recipe serve</li>
                      <li>Upload or click a picture of the dish</li>
                      <li>
                        Click on tag :
                        <ul>
                          <li>Write Hashtag</li>
                          <li>Click “Save”</li>
                        </ul>
                      </li>
                      <li>
                        Click Ingredients
                        <ul>
                          <li>
                            You can segregate the ingredient into sections for
                            eg like preparing Sandwich you can separately
                            mention ingredients required by the chutney (As a
                            Header) and for the sandwich (As a Header).
                            <ol>
                              <li>Type the ingredient and press done.</li>
                              <li>
                                For easy and quicker typing we have provided the
                                measurement keyboard.
                              </li>
                            </ol>
                          </li>
                          <li>
                            Type the list of ingredients or audio record  the
                            list
                          </li>
                          <li>
                            Click reference image of any ingredient if needed
                          </li>
                          <li>Click “Save”</li>
                        </ul>
                      </li>
                      <li>
                        Click Method
                        <ul>
                          <li>
                            Step 1 : Write the first step of recipe or audio
                            record the step or upload picture of recipe. You can
                            also copy and paste instructions.
                          </li>
                          <li>
                            Click on “Add another Steps” for writing as many
                            steps needed for the recipe
                          </li>
                          <li>
                            Click on ≡ if you need to change the order of steps
                            written
                          </li>
                          <li>Click on “x” If you want to delete the step</li>
                          <li>Click “Save”</li>
                        </ul>
                      </li>
                      <li>
                        Click Tips
                        <ul>
                          <li>
                            Incase there are any special cooking tips to be
                            mentioned you can write, audio record the tip(s).
                          </li>
                          <li>Click on “Save”</li>
                        </ul>
                      </li>
                      <li>
                        In-case you directly want to audio record the entire
                        recipe without following the above mentioned step you
                        can directly do so from the Media tab  or directly
                        upload the already written recipe from the phone gallery
                        by clicking “🗠” or by clicking on “📷” But you will have
                        to enter something in the ingredients and method tab
                      </li>
                      <li>
                        You can even copy paste a URL of website of your
                        favorite recipe for easy retrieval
                      </li>
                      <li>
                        Share on group : If you want to share the recipe with
                        your heirloom, click on share and select the group you
                        want to share the recipe with.
                        <ul>
                          <li>Click Share Now</li>
                        </ul>
                      </li>
                      <li>
                        Link to Custom : If the recipe is related to a
                        particular custom you can click on link to custom and
                        from the list of custom entered select to which custom
                        you want to link the recipe to.
                        <ul>
                          <li>Click Link Now</li>
                        </ul>
                      </li>
                      <li>
                        Link to Story : If the recipe is related to a particular
                        Story you can click on link to Story and from the list
                        of Stories entered select to which Story you want to
                        link the recipe to.
                        <ul>
                          <li>Click Link Now</li>
                        </ul>
                      </li>
                    </ol>
                  </li>
                  <li>Click on Submit</li>
                  <li>Hurray, congratulation your recipe has been saved.</li>
                  <li>Alternatively, click on ≡ on top left corner</li>
                  <li>Click on Preserve and Pass</li>
                  <li>Click on “Our Recipe”</li>
                  <li>Click on “Add New”</li>
                  <li>And follow step 1 – 17 mentioned above.</li>
                </ul>
              </li>

              <li>
                How do I audio record a recipe?
                <ul>
                  <li>After you click on “Add new”</li>
                  <li>
                    At each step of adding your recipe you will have an option
                    to audio record by clicking on
                  </li>
                </ul>
              </li>
              <li>
                How do I edit a recipe?
                <ul>
                  <li>
                    Note: You can edit a recipe that has been saved by you only.
                    To edit follow the below mentioned steps
                    <ol>
                      <li>Open Our Recipe page</li>
                      <li>Select the recipe that you want to edit</li>
                      <li>
                        You will see “” symbol on the right hand side near the
                        cover image of the recipe.
                      </li>
                      <li>
                        Then you can edit any section of the Recipe click Submit
                        once done.
                      </li>
                    </ol>
                  </li>
                </ul>
              </li>
              <li>
                How do I delete a recipe?
                <ul>
                  <li>
                    Note: You can delete a recipe that has been saved by you
                    only. To edit follow the below mentioned steps
                    <ol>
                      <li>Open Our Recipe page</li>
                      <li>Select the recipe that you want to delete</li>
                      <li>
                        You will see “” symbol on the right hand side near the
                        cover image of the recipe.
                      </li>
                      <li>
                        You will be prompted with a confirmation message “Are
                        you sure you want to delete the recipe”, Click Yes and
                        the recipe will be deleted.
                      </li>
                    </ol>
                  </li>
                </ul>
              </li>
              <li>
                How do I share recipe on group?
                <ul>
                  <li>
                    If you have created/added the recipe then
                    <ol>
                      <li>Open the recipe you want to share</li>
                      <li>Click on the option “Share Recipe”</li>
                      <li>Select the group you would like to share with</li>
                      <li>Click share</li>
                      <li>And save the recipe</li>
                    </ol>
                  </li>
                </ul>
              </li>

              <li>
                Can I share on social media?
                <ul>
                  <li>No, One cannot share the content on any social media.</li>
                </ul>
              </li>
              <li>
                How can undo / not share a recipe with a group previously
                shared?
                <ul>
                  <li>
                    Yes, you can undo and deselect to share a recipe which was
                    previously shared
                  </li>
                  <li>Open the recipe you want to change sharing setting of</li>
                  <li>
                    Click on “” edit symbol on the right hand side near the
                    cover image of the custom
                  </li>
                  <li>Click on “Share Recipe”</li>
                  <li>
                    Un-Select the group or members you would like to unshare the
                    recipe with.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            Customs:
            <ol>
              <li>
                How do I add a custom?
                <ul>
                  <li>There are two ways you can add a Custom</li>
                  <li>
                    Option One : On the Home-screen click on “Our Customs”
                  </li>
                  <li>
                    Click on “Add New”
                    <ol>
                      <li>Write the name of the Custom (Mandatory Field)</li>
                      <li>
                        Style the kind of custom from the dropdown (Mandatory
                        Field)
                      </li>
                      <li>
                        Style the Occurrence for the Drop down (Mandatory Field)
                      </li>
                      <li>Enter the Assign Date if applicable</li>
                      <li>Upload a photo of the custom</li>
                      <li>
                        Click on Tag
                        <ul>
                          <li>Enter relevant tags</li>
                          <li>Click on “Save”</li>
                        </ul>
                      </li>
                      <li>
                        Click on Significance
                        <ul>
                          <li>
                            Explain the custom by typing it or audio record it
                            or upload a picture
                          </li>
                          <li>Click on “Save”</li>
                        </ul>
                      </li>
                      <li>
                        Click on Things Needed
                        <ul>
                          <li>
                            Type the list of item needed for the custom, or
                            audio record the list or upload a picture of the
                            list
                          </li>
                          <li>Click on “Save”</li>
                        </ul>
                      </li>
                      <li>
                        Click on What to Do
                        <ul>
                          <li>
                            Step 1 : Stepwise type the steps to be performed or
                            audio record or upload a picture.
                          </li>
                          <li>
                            Add as many steps as needed and also change the
                            order of steps if needed
                          </li>
                          <li>Click on “Save”</li>
                        </ul>
                      </li>
                      <li>
                        You copy paste link of a website of the custom for
                        reference
                      </li>
                      <li>Set reminder : Select date for the reminder</li>
                      <li>
                        hare on group : If you want to share the custom with
                        your heirloom,
                        <ul>
                          <li>
                            Click on share and select the group you want to
                            share the custom with.
                          </li>
                          <li>Click Share Now</li>
                        </ul>
                      </li>
                      <li>
                        Link to Recipe : If the custom is related to a
                        particular Recipe you can click on link to Recipe and
                        from the list of Recipe entered select to which Recipe
                        you want to link the Custom to.
                        <ul>
                          <li>Click Link Now</li>
                        </ul>
                      </li>
                      <li>
                        Link to Story : If the Custom is related to a particular
                        Story you can click on link to Story and from the list
                        of Stories entered select to which Story you want to
                        link the Custom to.
                        <ul>
                          <li>Click Link Now</li>
                        </ul>
                      </li>
                      <li>Click on Submit</li>
                    </ol>
                  </li>
                  <li>Hurray, congratulation your Custom has been saved.</li>
                  <li>Option Two: Click on ≡ on top left corner</li>
                  <li>Click on Preserve and Pass</li>
                  <li>Click on “Our Customs”</li>
                  <li>Click on “Add New”</li>
                  <li>And follow step 1 – 15 mentioned above.</li>
                </ul>
              </li>
              <li>
                How do I edit a custom?
                <ul>
                  <li>
                    Note: You can only edit a custom that has been saved by you.
                    To edit follow the below mentioned steps
                    <ol>
                      <li>Open Our Customs page</li>
                      <li>Select the Custom that you want to edit</li>
                      <li>
                        You will see “” symbol on the right hand side near the
                        cover image of the Custom.
                      </li>
                      <li>
                        Then you can edit any section of the custom click Submit
                        once done.
                      </li>
                    </ol>
                  </li>
                </ul>
              </li>
              <li>
                How do I delete a custom?
                <ul>
                  <li>
                    Note: You can only delete a custom that has been saved by
                    you. To edit follow the below mentioned steps
                    <ol>
                      <li>Open Our Custom page</li>
                      <li>Select the Custom that you want to delete</li>
                      <li>
                        You will see “” symbol on the right hand side near the
                        cover image of the recipe.
                      </li>
                      <li>
                        You will be prompted with a confirmation message “Are
                        you sure you want to delete the Custom”, Click Yes and
                        the recipe will be deleted.
                      </li>
                    </ol>
                  </li>
                </ul>
              </li>
              <li>
                How do I share custom on group?
                <ul>
                  <li>
                    If you have added the Custom then
                    <ol>
                      <li>Open the Custom you want to share</li>
                      <li>Click on the option “Share Custom”</li>
                      <li>
                        Select the group or members you would like to share with
                      </li>
                    </ol>
                  </li>
                </ul>
              </li>
              <li>
                Can I share on social media?
                <ul>
                  <li>No, one cannot share the content in any social media.</li>
                </ul>
              </li>
              <li>
                How can undo / not share a Custom with a group or member
                previously shared?
                <ul>
                  <li>
                    Yes, you can undo and deselect to share a Custom which was
                    previously shared
                  </li>
                  <li>Open the Custom you want to change sharing setting of</li>
                  <li>
                    Click on “” edit symbol on the right hand side near the
                    cover image of the custom
                  </li>
                  <li>Click on “Share Custom”</li>
                  <li>
                    Un-Select the group or members you would like to unshare the
                    Custom with.
                  </li>
                </ul>
              </li>
            </ol>
          </li>
          <li>
            Story:
            <ol>
              <li>
                How do I add a Story
                <ul>
                  <li>There are two ways you can add a story</li>
                  <li>
                    Option one : On the Home-screen click on “Our Stories”
                  </li>
                  <li>
                    Click on “Add New”
                    <ol>
                      <li>Write the story Name (Mandatory field)</li>
                      <li>
                        Select the Genre from the drop down list (Mandatory
                        field)
                      </li>
                      <li>Add an image</li>
                      <li>
                        Click on Tag
                        <ul>
                          <li>Write the relevant tags for the story</li>
                          <li>Click on “Save”</li>
                        </ul>
                      </li>
                      <li>
                        Click on Story in One Line
                        <ul>
                          <li>
                            Write a summary or short description of the story,
                            you can even audio record or upload a picture
                          </li>
                          <li>Click on “Save”</li>
                        </ul>
                      </li>
                      <li>
                        Click On Main Story
                        <ul>
                          <li>
                            Write a summary or short description of the story,
                            you can even audio record or upload a picture
                          </li>
                          <li>Click on “Save”</li>
                        </ul>
                      </li>
                      <li>
                        Click On Main Story
                        <ul>
                          <li>
                            You can type the story or audio record the story or
                            upload the story through images
                          </li>
                          <li>Click on “Save”</li>
                        </ul>
                      </li>
                      <li>
                        Click on Moral
                        <ul>
                          <li>
                            If the story has a Moral, you can either type or
                            audio record the moral of the story
                          </li>
                          <li>Click on “Save”</li>
                        </ul>
                      </li>
                      <li>
                        Click on media : If you can any other audio recording or
                        picture for the story to be uploaded
                      </li>
                      <li>
                        Add URL: You can Copy paste a link of the website of the
                        story for easy retrieval
                      </li>
                      <li>
                        Set as sub-Story to existing story: If the story is a
                        continuation of any existing story you can link the two.
                      </li>
                      <li>
                        hare on group : If you want to share the story with your
                        heirloom,
                        <ul>
                          <li>
                            Click on share and select the group you want to
                            share the story with
                          </li>
                          <li>Click Share Now</li>
                        </ul>
                      </li>
                      <li>
                        Link to Recipe : If the Story is related to a particular
                        Recipe you can click on link to Recipe and from the list
                        of Recipe entered select to which Recipe you want to
                        link the story to.
                      </li>
                      <li>
                        Link to Custom : If the Story is related to a particular
                        Custom you can click on link to Custom and from the list
                        of Customs entered select to which Custom you want to
                        link the story to.
                      </li>
                      <li>And click on submit button</li>
                      <li>Hurray, Congratulation your Story has been saved.</li>
                    </ol>
                  </li>
                  <li>Option Two: Click on ≡ on top left corner</li>
                  <li>Click on Preserve and Pass</li>
                  <li>Click on “Our Stories”</li>
                  <li>Click on “Add New”</li>
                  <li>And follow step 1 – 15 mentioned above</li>
                </ul>
              </li>
              <li>
                How do I edit a Story?
                <ul>
                  <li>
                    Note: You can only edit a story that has been saved by you.
                    To edit follow the below mentioned steps
                    <ol>
                      <li>Open Our Story page</li>
                      <li>Select the Story that you want to edit</li>
                      <li>
                        You will see “” symbol on the right hand side near the
                        cover image of the Story.
                      </li>
                      <li>
                        Then you can edit any section of the Story click Submit
                        once done.
                      </li>
                    </ol>
                  </li>
                </ul>
              </li>
              <li>
                How do I delete a Story?
                <ul>
                  <li>
                    Note: You can only delete a story that has been saved by
                    you. To edit follow the below mentioned steps
                  </li>
                  <li>Open Our Story page</li>
                  <li>Select the Story that you want to delete</li>
                  <li>
                    You will see “” symbol on the right hand side near the
                    cover image of the Story.
                  </li>
                  <li>
                    You will be prompted with a confirmation message “Are you
                    sure you want to delete the Story”, Click Yes and the recipe
                    will be deleted.
                  </li>
                </ul>
              </li>
              <li>
                How do I share Story on group?
                <ul>
                  <li>
                    If you have added the Story then
                    <ol>
                      <li>Open the Story you want to share</li>
                      <li>Click on the option “Share Story”</li>
                      <li>
                        Select the group or members you would like to share with
                      </li>
                    </ol>
                  </li>
                </ul>
              </li>
              <li>
                Can I share on social media?
                <ul>
                  <li>No, One cannot share the content in any social media.</li>
                </ul>
              </li>
              <li>
                How can undo / not share a Story with a group or member
                previously shared?
                <ul>
                  <li>
                    Yes, you can undo and deselect to share a Story which was
                    previously shared
                  </li>
                  <li>Open the Story you want to change sharing setting of</li>
                  <li>
                    Click on “” edit symbol on the right hand side near the
                    cover image of the Story
                  </li>
                  <li>Click on “Share Story”</li>
                  <li>
                    Un-Select the group or members you would like to unshare the
                    Story with.
                  </li>
                </ul>
              </li>
              <li>
                Subscription:
                <ul>
                  <li>
                    How much does a membership Cost?
                    <ol>
                      <li>
                        Sign up using your mobile Number and enjoy 30 days free
                        access to the App, upgrade your members at Rs 999/- per
                        annum after subscribing, you’ll have unlimited access to
                        the App.
                      </li>
                      <li>
                        Only 1 family member needs to subscribe to the app in
                        order to enjoy the features of the app
                      </li>
                    </ol>
                  </li>
                  <li>
                    What does premium membership include?
                    <ul>
                      <li>Create unlimited groups</li>
                      <li>Preserve & Pass Unlimited Recipes</li>

                      <li>Preserve & Pass Unlimited Stories</li>
                      <li>Preserve & Pass Unlimited Customs</li>
                      <li>
                        Audio record, type or upload existing recipes, stories
                        and custom
                      </li>
                      <li>Create unlimited memories</li>
                      <li>Epi-center of your family bonding</li>
                      <li>Share freely without your group and individually</li>
                      <li>Always be connected to your roots</li>
                      <li>
                        No more wasting time in searching through old messages
                      </li>
                      <li>One stop for all your family tales</li>
                    </ul>
                  </li>
                  <li>
                    How do I subscribe?
                    <ul>
                      <li>click on ≡ on top left corner</li>
                      <li>Click on your Name</li>
                      <li>
                        Below your name you will see the Number of pending in
                        your Trial package and upgrade up option
                      </li>
                      <li>Click on Upgrade now</li>
                      <li>And click on Subscribe</li>
                    </ul>
                  </li>
                  <li>
                    What are the payment methods?
                    <ul>
                      <li>
                        You can purchase via your Apple account on the App Store
                        and Google Play account on the Play Store.
                      </li>
                    </ul>
                  </li>
                  <li>
                    How do I unsubscribe?
                    <ul>
                      <li>Click on ≡ on top left corner</li>
                      <li>Click on your Name</li>
                      <li>Click on “Premium Member”</li>
                      <li>Click on cancel Membership</li>
                      <li>Follow through the cancellation steps</li>
                      <li>
                        After you cancel, you will have access to all content
                        for viewing.
                      </li>
                      <li>Renew membership anytime</li>
                    </ul>
                  </li>
                  <li>
                    What happens to my data after I unsubscribe?
                    <ul>
                      <li>
                        The data will always be available for viewing until you
                        delete your account
                      </li>
                    </ul>
                  </li>
                  <li>
                    How do I delete my Account
                    <ul>
                      <li>
                        Note: When your account is deleted, your profile,
                        recipes, Customs, Stories, Groups, queries are
                        permanently removed. Deleting your account is an
                        irreversible step. Please read our privacy policy and
                        terms of use for further information.
                        <ol>
                          <li>Click on ≡ on top left corner</li>
                          <li>Click on your Name</li>
                          <li>
                            At the bottom on the screen you will read “Delete
                            Account”
                          </li>
                        </ol>
                      </li>
                      <li>
                        NOTE: If you subscribed via iTunes, or Google Play,
                        deleting your account will not end your Heirloom Tales
                        subscription. Please reach out to the appropriate team
                        to get assistance on cancelling the subscription.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Report a Violation
                    <ul>
                      <li>
                        In-case you come across any offensive content please
                        bring it to our immediate attention by writing us on{" "}
                        <a href="Controls@heirloomtales.in">
                          Controls@heirloomtales.in
                        </a>{" "}
                        with the name and contact detail of the offender and if
                        possible screenshot of the offensive content posted.
                        This will help us identity and take action against the
                        offender at the earliest.
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default Faq;
