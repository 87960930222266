import React from "react";

const PrivacyPolicy = () => {
  return (
    <div>

      <h2 className="text-center pt-5"> Privacy Policy</h2>
      <p className="text-center">
        This privacy policy was last updated on 5th July 2021 (“Privacy Policy”)
      </p>
      <div
        className="text-center p-5
      "
      >
        <p className="text-left">
          1. General
          <br />
          <p className="border-dark border-top pt-3">
            www.heirloomtales.in an internet based portal (“Website”) and the
            mobile application [Heirloom Tales] (“App”) is owned and operated by
            Heirloom Tales LLP, a registered LLP within the meaning of the
            Limited Liability Partnership Act, 2008 having its registered office
            at 1st Floor, Ambika 10th Road, Plot No. 54, Vile Parle West, Juhu
            Scheme Mumbai. The Website and App are hereinafter collectively
            referred to as the “Platform”. We value your privacy and take our
            responsibilities in relation to your data seriously. This Privacy
            Policy shall be read in conjunction and together with the Terms of
            Use and forms an integral part of the Terms of Use between you and
            us. Capitalized terms not defined herein shall have the meaning
            attributed to them under the Terms of Use. This Privacy Policy shall
            be construed to be provided in compliance with the Information
            Technology Act 2000 and the rules framed thereunder (as amended from
            time to time) ("IT Act"). The terms “Personal Information” and
            “Sensitive Personal Data or Information” shall have the meaning
            ascribed to it under the applicable laws of India. In this Privacy
            Policy, we explain what Personal Information and Sensitive Personal
            Data or information (“Personal Information”) is collected and what
            we do with the data we obtain about you via the Platform. In our
            processing, we comply with the requirements of privacy legislation
            of India. That means, among other things, that:
          </p>
          <p className="text-left">
            <ul>
              <li>
                We clearly state the purposes for which we process Personal
                Information and Sensitive Personal Data or Information. We do
                this by means of this Privacy Policy;
              </li>
              <li>
                We aim to limit our collection of Personal Information and
                Sensitive Personal Data or Information to only such data
                required for legitimate purposes;
              </li>
              <li>
                We take appropriate security measures to protect your Personal
                Information and Sensitive Personal Data or Information and also
                require this from parties that process such data on our behalf;
              </li>
              <li>
                We respect your right to access your Personal Information and
                Sensitive Personal Data or Information or have it corrected or
                deleted, at your request.
              </li>
            </ul>
          </p>
          <p>
            Please read this Privacy Policy carefully before accessing or using
            the Platform. By (i) using the Platform or any facility or services
            provided through the Platform in any way; or (ii) merely browsing
            the Platform; you confirm that you have the capacity to enter into a
            legally binding contract and that you have read, understood and
            irrevocably accepted and consented to the terms and conditions under
            this Privacy Policy as also the Terms of Use available on the
            Platform. If you do not agree with these or have any reservations,
            please do not use or access our Platform.
          </p>
          <p>
            By accepting the Privacy Policy, you expressly consent to our use
            and disclosure of your Personal Information in accordance with this
            Privacy Policy.
          </p>
          <p>
            Please note that any information that is freely available or
            accessible in public domain or furnished under the Right to
            Information Act, 2005 or any other law for the time being in force
            shall not be regarded as Personal Information for the purposes of
            this Privacy Policy and we shall not be obliged to take any measures
            to protect the same since the same is freely available in the public
            domain.
          </p>
          <p>
            We expressly disclaim any representation or warranty that the
            Platform complies with all applicable laws and regulations outside
            of India. If you are accessing or using the Platform from any
            location outside India, you do so at your own risk, and will be
            solely liable for compliance with any local laws and customs, as may
            be applicable. You undertake and confirm that in your use, you shall
            comply with all applicable laws, regulations, directives,
            legislations and customs which apply to your use of the Platform in
            whatever country you are physically located.
          </p>
          <p>
            2. Purpose
            <br />
            <p className="border-dark border-top pt-3">
              (a) The primary purpose of collection of Personal Information set
              out herein, is to allow you to access the Platform and to use the
              service and various features provided on the Platform.
            </p>
            <p>
              (b) The other purposes for which such Personal Information is
              collected is as follows:
            </p>
          </p>
          <ol>
            <li>
              To set up and facilitate log-in to a user account on Platform.
              Once you create a profile, others will be able to see in your
              profile certain information about your activity on the Platform.
            </li>
            <li>
              Initiate data and documents transfer pertaining to the services
              and obtain logistics information and other information thereunder
            </li>
            <li>
              To contact and communicate with you regarding our Platform and
              existing services availed by you, including notifications of any
              alerts or updates to our Platform and services
            </li>
            <li>
              To support the services that you have or want to avail and to
              notify you about any changes to our services.
            </li>
            <li>
              Comply with and enforce applicable legal requirements, relevant
              industry standards and the provisions of this Privacy Policy.
            </li>
            <li>To provide language and location based personalization.</li>
            <li>Compiling and analyzing statistics for website improvement.</li>
            <li>
              Protect against, identify and prevent fraud and other unlawful
              activity, claims and other liabilities.
            </li>
            <li>
              To enforce our terms, conditions, and policies and any of our
              rights, or the rights of our affiliate companies, or other users
              of the Platform.
            </li>
            <li>
              To administer the Platform and for internal operations, including
              troubleshooting, data analysis, testing, research, security,
              fraud-detection, account management, and survey purposes.
            </li>
            <li>
              To provide you quick access to your previous searches. To verify
              your identity and for maintaining your account security. To
              facilitate the sharing of any media such as audios, videos, and
              images using the Platform.
            </li>
            <li>
              To facilitate your participation in any contest. To display
              interest-based advertising to you in the Platform, to improve our
              advertising and measurement systems so we can show you relevant
              ads, to pre-fill forms in ads, and to measure the effectiveness
              and reach of ads and services.
            </li>
            <li>
              For marketing and promotional purposes, such as to send you news,
              special offers, and promotions, or to otherwise contact you about
              products or information we think may interest you, including
              information about third-party products and services
            </li>
          </ol>
          <p>
            3. Information Collected through the Platform
            <p className="border-dark border-top pt-3">
              The types of Personal Information we may collect through the
              Platform shall include, but not be limited to:
            </p>
            <p>
              <ul>
                <li>
                  Demographic, identity & contact Data (for e.g., name, date of
                  birth, email address, current city, hometown, cell no.)
                </li>
                <li>
                  Contact information of others to whom you ask us to send
                  information, as permitted by law;
                </li>
                <li>Professional and Educational Data;</li>
                <li>
                  Preferences or Interests related Data (for e.g., preferences,
                  comments, posts, feedbacks, interests, likes/dislikes, other
                  content you submit)
                </li>
                <li>
                  Photos & Videos. If you upload images to the website, you
                  should avoid uploading images with embedded location data
                  (EXIF GPS) included. Visitors to the website can download and
                  extract any location data from images on the website
                </li>
                <li>
                  Cookie data and other browser-related information as described
                  below in this Privacy Policy; and
                </li>
                <li>
                  Any other information that may be provided through the
                  Platform (including through comments or questions fields,
                  etc.)
                </li>
              </ul>
            </p>
          </p>
          <p>
            IP addresses and cookies:
            <br />
            <p>
              Our Platform use cookies and other tracking mechanisms to collect
              data about you. We collect Personal Information about you via our
              Platform using permissions such as camera, microphone, storage,
              contacts, phone. IOS and Android devices will notify you the
              permissions that our Platform seeks and give you an option to
              consent or refuse the permission. We may collect information about
              your computer, including where available, your IP address,
              operating system and browser type, general internet usage Cookies
              contain information that is transferred to your computer's hard
              drive. They help us to improve the Platform, and to deliver better
              and more personalised services. They enable us to: estimate your
              usage size and usage pattern; store information about your
              preferences, and so allow us to customise the Platform
              accordingly; speed up your searches; and recognise you when you
              return to our Platform. Unless you have adjusted your browser
              setting so that it will refuse cookies, our system will issue
              cookies when you log on to the Platform. You may refuse to accept
              cookies by activating the setting on your browser which allows you
              to refuse the setting of cookies. However, if you select this
              setting, you may be unable to access certain parts of our
              Platform. Please note that our advertisers may also use cookies,
              over which we have no control.
            </p>
          </p>
          <p>
            4. Disclosure of your information
            <p className="border-dark border-top pt-3">
              We do not sell or otherwise disclose personal information we
              collect about you, except as described in this Privacy Policy.
            </p>
            <p>We disclose your information in the following manner:</p>
            <ol>
              <li>
                People and Accounts that you Share and Communicate With: Our
                Platform lets you post and share personal data, comments,
                materials, and other content. By uploading data about yourself
                like photos, posts, likes, etc., the members of your group or
                whoever you share your data with may be able to view your
                profile and other uploaded content on the Platform. Any data you
                disclose as such may be collected and used by others, may be
                indexable by search engines, and might not be able to be
                removed. Please be careful when disclosing information.
              </li>
              <li>
                Service Providers: We may engage service providers while
                providing our services to you with whom we may share your
                personal information for performing certain functions. We do not
                authorize these service providers to use or disclose the
                information except as necessary to perform services or comply
                with applicable legal requirements.
              </li>
              <li>
                Partners, Subsidiaries and Affiliates: The information collected
                about you may be accessed by or shared with our partners,
                subsidiaries and affiliates whose use and disclosure of your
                personal information is subject to this Privacy Policy.
              </li>
              <li>
                Business Transfers: We may disclose or transfer information,
                including personal information, as part of any merger, sale, and
                transfer of our assets, acquisition or restructuring of all or
                part of our business, bankruptcy, or similar event.
              </li>
              <li>
                Storage and distribution: To operate our global Services, we may
                need to store and distribute content and information in data
                centres and systems around the world, including outside your
                country of residence. The use of this global infrastructure is
                necessary and essential to provide our Services. This
                infrastructure may be owned or operated by our service providers
                including affiliated companies.
              </li>
              <li>
                Others: Advertisers and advertising networks that require the
                data to select and serve relevant adverts to you and others. We
                do not disclose information about identifiable individuals to
                our advertisers, but we may provide them with aggregate
                information about our users (for example, we may inform them
                demographics about the users who saw their ads or content on any
                given day). We may also use such aggregate information to help
                advertisers reach the kind of audience they want to target. We
                may also disclose your Personal Information to government bodies
                or law enforcement agencies, if required to do so under any
                applicable laws or if we have good-faith belief that it is
                reasonably necessary to share your personal data or information
                in order to comply with any legal obligation or any government
                request; or to enforce the Terms of Use and this Privacy Policy;
                or to respond to claims that any content violates the rights of
                a third party; or to respond to claims that any content is
                defamatory; or to protect the rights or prevent any harm to the
                property, or safety of the users or public; or to detect,
                prevent or otherwise address public safety, fraud, security or
                technical issues. We may disclose your Personal Information to
                any party appointed or requested by applicable regulators to
                carry out investigations or audits of our activities.
              </li>
            </ol>
          </p>
          <p>
            6. Security Practices
            <p className="border-dark border-top pt-3">
              We are committed to the security of Personal Information. We
              maintain appropriate administrative, organizational, technical and
              physical safeguards designed to protect the Personal Information
              provided by you against accidental, unlawful or unauthorized
              destruction, loss, alteration, access, disclosure or use. This
              ensures that only the necessary persons have access to your
              Personal Information, that access to the Personal Information is
              protected, and that our security measures are regularly reviewed.
              We will take all steps reasonably necessary to ensure that your
              Personal Information is treated securely and in accordance with
              this Privacy Policy. Where we have given you (or where you have
              chosen) a password which enables you to access certain parts of
              the Platform, you are responsible for keeping this password
              confidential. We ask you not to share your password with anyone.
              Unfortunately, the transmission of information via the internet is
              not completely secure. While we strive to use a commercially
              acceptable means to protect the Personal Information you provide,
              we cannot guarantee the security of your data transmitted to our
              Platform; any transmission is at your own risk.
            </p>
          </p>
          <p>
            7. Data Retention
            <p className="border-dark border-top pt-3">
              We retain your Personal Information and data for as long as it is
              required to fulfil the purposes outlined in this Privacy Policy
              and for legal or regulatory reasons. All users can see, edit, or
              delete their Personal Information at any time (except they cannot
              change their username). We will entertain your request for
              deletion of any Personal Information and data, however, there is a
              strong possibility that copies of any public content will be
              retained indefinitely in our systems, including in cached and
              archived pages of the Platform, or if other users have copied or
              saved that information. In addition, due to the nature of the
              internet, copies of your content, including content that you have
              removed or deleted from your account, may also exist elsewhere on
              the internet and be retained indefinitely. If you leave a comment,
              the comment and its metadata are retained indefinitely. This is so
              we can recognize and approve any follow-up comments automatically
              instead of holding them in a moderation queue.
            </p>
          </p>
          <p>
            8. Third party websites
            <p className="border-dark border-top pt-3">
              Our Platform may contain links to other third-party websites as
              part of our features. These websites operate independently from
              us. We are not responsible for any collection or disclosure of
              your information by such third-party websites. Such third-party
              websites accessible via the Platform may have their own privacy
              policies governing the collection, storage, transfer, retention
              and/or disclosure of your information that you may be subject to.
              We recommend that you read the privacy policies of the third-party
              websites you visit. We are not responsible for any such websites’
              or apps’ content, any use of these websites or apps, or the
              privacy practices of these websites or apps.
            </p>
          </p>
          <p>
            9. Global Operations
            <p className="border-dark border-top pt-3">
              We share information globally, both internally within partners,
              subsidiaries and affiliates and externally with our service
              providers, and with those with whom you communicate around the
              world, in accordance with this Privacy Policy. Your information
              may, for example, be transferred or transmitted to, or stored and
              processed in, the countries or territories where our partners,
              subsidiaries and affiliates, or our service providers are located;
              or any other country or territory globally where our Services are
              provided outside of where you live for the purposes as described
              in this Privacy Policy. We may use global infrastructure and data
              centers. These transfers are necessary to provide the global
              Services set forth in our Terms. Please keep in mind that the
              countries or territories to which your information is transferred
              may have different privacy laws and protections than what you have
              in your home country or territory.
            </p>
          </p>
          <p>
            10.Your Privacy Rights
            <p className="border-dark border-top pt-3">
              We offer you certain choices in connection with the Personal
              Information we collect from you. You may opt out from unwanted
              e-mail communications from us by following the instructions in the
              message. However, until your account is deleted you will continue
              to receive all system e-mails. Additionally, you have the
              following rights:
            </p>
            <p>
              <ul>
                <li>
                  You have the right to access your Personal Information and
                  data that is known to us.
                </li>
                <li>
                  You have the right to supplement, correct, have deleted or
                  blocked your Personal Information and data or you can delete
                  your account whenever you wish. However, the history of your
                  activities and account on our Platform remains available to us
                </li>
                <li>
                  You have the right to revoke the consent you previously
                  provided to us. We will comply with this going forward.
                  However, please note that revoking your consent may negatively
                  affect your use of the Platform to some extent.
                </li>
                <li>
                  You may object to the processing of your Personal Information
                  and data on legitimate grounds. We will comply with this going
                  forward.
                </li>
                <li>
                  Right to not be subjected to Automated individual
                  decision-making, including profiling.
                </li>
              </ul>
              <p>
                Please make sure to always clearly state who you are, so that we
                can be certain that we do not modify or delete any data of the
                wrong person.
              </p>
              <br />
              <p>
                In the event of a change in the law applicable to data
                protection in India, you hereby expressly consent to our
                continued use, storage, collection and disclosure of your
                Personal Information to the fullest extent permitted under such
                applicable law. We may reach out to you for obtaining additional
                consents and approvals as required under the amended law and you
                will be required to comply with such requests. Should you choose
                to not provide us with such additional consents and approvals,
                we may have to discontinue your access to the Platform.
              </p>
            </p>
          </p>
          <p>
            10. Amendments to this Privacy Policy
            <p className="border-dark border-top pt-3">
              We may modify, add or delete from, this Privacy Policy from time
              to time in our sole discretion. We shall not be under the
              obligation to separately or personally notify you, whether as a
              registered user or not, of any changes made to this Privacy Policy
              . You are requested to regularly visit the Platform to view the
              most current Privacy Policy. Your continued access to, or use of
              the Platform, will be deemed to be an acceptance of the said
              changes. If you do not agree with this Privacy Policy at any time,
              in part or as a whole, your only remedy is to cancel your account
              or to cease use of the Platform.
            </p>
          </p>
          <p>
            11. Disputes
            <p className="border-dark border-top pt-3">
              Any disputes in connection with this Privacy Policy or protection
              of your personal information and privacy, are subject to the
              provisions specified in the Terms of Use, including but not
              limited to any provisions related to indemnification and choice of
              law.
            </p>
          </p>
          <p>
            12. Contact details
            <p className="border-dark border-top">
              In accordance with the IT Act and rules made thereunder (as
              amended from time to time), the contact details of the Grievance
              Officer are provided Controls@heirloomtales.in
            </p>
          </p>
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
